












































































import { Subject } from "@/types/subject";
import { Vue, Component, Watch } from "vue-property-decorator";
import subjectModule from "@/store/modules/subjects";
import Factory from "@/types/Factory";
import { Dictionary } from "@/types/helpers";
import VMarkdownEditor from "@/components/utility/markdown.vue";

@Component({
  components: {
    VMarkdownEditor,
  },
})
export default class SubjectDetails extends Vue {
  showDialog = false;
  resolve: ((value: Subject | PromiseLike<Subject>) => void) | undefined;
  reject: ((value: boolean | PromiseLike<boolean>) => void) | undefined;
  errors: Dictionary<string | string[]> = {};
  menu = false;
  subject = Factory.getSubject();

  mounted(): void {
    this.loadSubject();
  }

  @Watch("$route.params.subject_id")
  updateSubject(): void {
    this.loadSubject();
  }

  async loadSubject(): Promise<void> {
    try {
      const subjectId = this.$route.params.subject_id;
      await subjectModule.selectSubject(subjectId);
      if (subjectId) {
        this.subject = subjectModule.getCurrentSubject!;
      }
    } catch (err) {
      if (err.response.status == 404) {
        this.$router.replace({ name: "NotFound" });
      }
    }
  }

  get isMenuOpen(): boolean {
    return this.menu;
  }

  async save(): Promise<void> {
    try {
      const subject = await subjectModule.save(this.subject);

      this.errors = {};
      this.showDialog = false;

      if (this.resolve) this.resolve(subject);
      this.$router.push({
        name: "subjectDisplay",
        params: { subject_id: subject.id },
      });
      this.$toast.success(this.$t("global.success").toString());
    } catch (err) {
      this.errors = err.response.data.errors;
      this.$toast.error(this.$t("global.error-form").toString());
    }
  }

  close(): void {
    this.errors = {};
    this.showDialog = false;
  }

  get swatchStyle(): Dictionary<string> {
    const [color, menu] = [this.subject.color, this.menu];
    return {
      backgroundColor: color,
      cursor: "pointer",
      height: "30px",
      width: "30px",
      borderRadius: menu ? "50%" : "4px",
      transition: "border-radius 200ms ease-in-out",
    };
  }
}
